import authService from "./AuthService";
import Loading from '../Layouts/Loading';
import { Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

const Security = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    authService.isAuthenticatedUser()
      .then((result) => {
        setIsAuthenticated(result.auth);
        setLoading(false);
        setUser(result.user);
      })
      .catch(() => {
        setIsAuthenticated(false);
        setLoading(false);
        setUser(null);
      });
  }, []);
  
  if (loading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

return React.cloneElement(children, user);
};

export default Security;