import Cookies from 'js-cookie'
import ApiService from './ApiService';

const authService = {
  isAuthenticated: false,

  isAuthenticatedUser: async () => {
    try {
      // Faites une requête HTTP vers votre endpoint pour vérifier l'authentification
      const url = '/api/auth/logged';
      const response = await ApiService.get(url, null, Cookies.get("token_auth"));

      if (response.status === 200) {
        authService.isAuthenticated = true;
        return {
          'auth': true,
          'user': {
            'firstname': response.firstname,
            'lastname': response.lastname,
            'email': response.email,
            'phone_number': response.phone_number,
          }
        };
      } else {
        authService.isAuthenticated = false;
        return {
          'auth': false,
          'user': null
        };
      }
    } catch (error) {
      console.error('Erreur lors de la vérification de l\'authentification', error);
      authService.isAuthenticated = false;
      return false;
    }
  },
};

export default authService;